#root {
    min-width: 1200px;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.button-spread {
    margin-right: 15px;
    margin-bottom: 5px;
}

.linebreak-text {
    white-space: pre-line;
}

.row-spacing {
    margin-top: 15px;
}

.col.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.red-cell {
    background-color: #ae1505;
    color: white;
    cursor: pointer;
    text-align: center;
}

.blue-cell {
    background-color: #040fe2;
    color: white;
    cursor: pointer;
    text-align: center;
}

.btn-margin {
    margin-top: 5px;
}

.form-group label.required::after {
    content: " *";
    color: red;
    font-size: large;
}

.navbar-text {
    padding: 0.5rem;
}

.overflow-table-wrapper {
    overflow-x: scroll;
}

.overflow-table {
    width: auto;
    min-width: 1200px;
}

.dropdown-menu a:hover {
    text-decoration: none;
}
